// 车辆燃油报表
<template>
  <div class="main noScroll">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs"
            key="parent"
            @on-click="onClickTabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
        <div class="flex a-center"
             slot="extra">
          <Icon custom="i-icon icon-tishi1"
                size="16"
                color="#FF9B77"
                class="m-r-5"></Icon>
          今日数据会在次日凌晨1点更新到本系统
        </div>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <template v-if="currentTabIndex===0||currentTabIndex===1||currentTabIndex===2||currentTabIndex===3">
              <Select v-model="selectType"
                      placeholder="查询分类"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in selectTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Select v-model="carType"
                      placeholder="车辆类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in typeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <!-- <MyTreeSelect :value="carNumber"
                            placeholder="车牌号"
                            :data="carTreeArr"
                            search
                            checked
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            @onCheckChange="onChangeName">
              </MyTreeSelect> -->
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
              <Select v-model="remoteCar"
                      filterable
                      clearable
                      placeholder="请输入车牌号"
                      :remote-method="remoteMethod"
                      :loading="remoteCarLoading"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteCar">
                <Option v-for="(option, index) in remoteCarList"
                        :value="option.id"
                        :key="option.id">{{option.name}}</Option>
              </Select>
              <!-- <template v-if="currentTabIndex===2">
                <div class="flex a-center m-r-10">
                  <span>漏油超过：</span>
                  <Input v-model="oilMass"
                         placeholder=""
                         class="m-r-5"
                         style="width:100px" />
                  <span>L</span>
                </div>
              </template>
              <template v-if="currentTabIndex===3">
                <div class="flex a-center m-r-10">
                  <span>加油超过：</span>
                  <Input v-model="oilMass"
                         placeholder=""
                         class="m-r-5"
                         style="width:100px" />
                  <span>L</span>
                </div>
              </template> -->
              <DatePicker :value="date"
                          type="daterange"
                          ref="formDate"
                          :clearable='false'
                          :editable='false'
                          :start-date="new Date()"
                          placeholder="请选择时间范围"
                          @on-change="onChangeDate"
                          :options="dateOptions"
                          style="width:260px"
                          v-show="selectType===1"></DatePicker>
              <!-- <DatePicker :value="dateMonth"
                          type="month"
                          placeholder="选择月份"
                          @on-change="onChangeDate"
                          style="width:160px"
                          v-if="selectType===2"></DatePicker> -->
              <Monthrange :itemStyle="{width:'160px','margin-right':'10px'}"
                          :dateMonthrange="dateMonthrange"
                          @onChange="onChangeMonthrange"
                          v-show="selectType===2"></Monthrange>
            </template>
            <template v-if="currentTabIndex===4">
              <!-- <Select v-model="departmentType"
                      placeholder="机构类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in departmentTypeArr"
                        :value="item.name"
                        :key="item.id">{{ item.name }}</Option>
              </Select> -->
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
              <Select v-model="carType"
                      placeholder="车辆类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in typeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <DatePicker :value="dateMonth"
                          type="month"
                          placeholder="选择月份"
                          @on-change="onChangeDate"
                          :options="dateOptions"
                          class="m-r-10"
                          style="width:160px"></DatePicker>
            </template>
          </div>
          <div class="flex">
            <template v-if="currentTabIndex===0||currentTabIndex===1||currentTabIndex===2||currentTabIndex===3">
              <template v-if="selectType===1">
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/carFuelReport/query'])"
                        :ghost="buttonIndex == 2 ? false : true"
                        @click.stop="onClickNearlyThreeDay">近三天</Button>
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/carFuelReport/query'])"
                        :ghost="buttonIndex == 0 ? false : true"
                        @click.stop="onClickNearlySevenDay">近七天</Button>
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/carFuelReport/query'])"
                        :ghost="buttonIndex == 1 ? false : true"
                        @click.stop="onClickCurrentMonth">本月</Button>
              </template>
              <template v-if="selectType===2">
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/carFuelReport/query'])"
                        :ghost="buttonIndex == 3 ? false : true"
                        @click.stop="onClickPreMonthDay(3)">近三月</Button>
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/carFuelReport/query'])"
                        :ghost="buttonIndex == 4 ? false : true"
                        @click.stop="onClickPreMonthDay(6)">近六月</Button>
              </template>
            </template>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/carFuelReport/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/carFuelReport/query'])"
                    @click.stop="onResetSearchData">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-if="currentTabIndex===0">
        <div class="section">
          <Tabs v-model="currentOilConsumptionEchartsTabIndex"
                class="tabs"
                key="childEcharts2">
            <TabPane v-for="item in oilConsumptionEchartsTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <Echarts height="400px"
                   :options="totalOptions"
                   :data="fuelConsumptionEcharsData"
                   v-show="fuelConsumptionEcharsData.length"></Echarts>
          <div class="noData"
               v-show="fuelConsumptionEcharsData.length===0">
            <img src="@/assets/common/tubiaono.png">
            暂无数据
          </div>
        </div>
      </template>
      <template v-if="currentTabIndex===2">
        <div class="section">
          <Tabs v-model="currentOilLeakEchartsTabIndex"
                class="tabs"
                key="childEcharts3">
            <TabPane v-for="item in oilLeakEchartsTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <Echarts height="400px"
                   :options="totalOptions"
                   :data="oilSpillEcharsData"
                   v-show="oilSpillEcharsData.length"></Echarts>
          <div class="noData"
               v-show="oilSpillEcharsData.length===0">
            <img src="@/assets/common/tubiaono.png">
            暂无数据
          </div>
        </div>
      </template>
      <template v-if="currentTabIndex===3">
        <div class="section">
          <Tabs v-model="currentRefuelEchartsTabIndex"
                class="tabs"
                key="childEcharts4">
            <TabPane v-for="item in refuelEchartsTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <Echarts height="400px"
                   :options="totalOptions"
                   :data="refuelEcharsData"
                   v-show="refuelEcharsData.length"></Echarts>
          <div class="noData"
               v-show="refuelEcharsData.length===0">
            <img src="@/assets/common/tubiaono.png">
            暂无数据
          </div>
        </div>
      </template>
      <template v-if="currentTabIndex===4">
        <div class="section">
          <div class="section-title">
            <span>加油量</span>
          </div>
          <ul class="section-list">
            <li class="section-item"
                v-for="(item,index) in refuelAmountArr"
                :key="index">
              <span class="fs-14">{{item.title}}</span>
              <span class="fs-48 m-l-8">{{item.value}}</span>
            </li>
          </ul>
        </div>
        <div class="section">
          <Tabs v-model="currentTotalEchartsTabIndex"
                class="tabs"
                key="child7">
            <TabPane v-for="item in totalEchartsTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <!-- 作业分布饼图 -->
          <div class="flex j-betweem w-full">
            <div class="w-p-50">
              <Echarts height="400px"
                       :options="workPieChartOptions"
                       :data="refuelSummaryWorkOptionsData"
                       v-show="refuelSummaryWorkOptionsData.length"></Echarts>
              <div class="noData"
                   v-show="refuelSummaryWorkOptionsData.length===0">
                <img src="@/assets/common/tubiaono.png">
                暂无数据
              </div>
            </div>
            <div class="w-p-50">
              <!-- 车型分布饼图 -->
              <Echarts height="400px"
                       :options="cartypePieChartOptions"
                       :data="refuelSummaryCartypeOptionsData"
                       v-show="refuelSummaryCartypeOptionsData.length"></Echarts>
              <div class="noData"
                   v-show="refuelSummaryCartypeOptionsData.length===0">
                <img src="@/assets/common/tubiaono.png">
                暂无数据
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="section">
        <div class="flex a-center j-between">
          <!-- 耗油报表item table -->
          <template v-if="currentTabIndex===0">
            <Tabs v-model="currentOilConsumptionTabIndex"
                  class="tabs"
                  key="child2">
              <TabPane v-for="item in oilConsumptionTabArr"
                       :key="item.name"
                       :label="item.name"
                       :disabled="currentOilConsumptionTabIndex==0"></TabPane>
            </Tabs>
          </template>
          <template v-if="currentTabIndex===2">
            <Tabs v-model="currentOilLeakTabIndex"
                  class="tabs"
                  key="child3">
              <TabPane v-for="item in oilLeakTabArr"
                       :key="item.name"
                       :label="item.name"
                       :disabled="currentOilLeakTabIndex==0"></TabPane>
            </Tabs>
          </template>
          <template v-if="currentTabIndex===3">
            <Tabs v-model="currentRefuelTabIndex"
                  class="tabs"
                  key="child4">
              <TabPane v-for="item in refuelTabArr"
                       :key="item.name"
                       :label="item.name"
                       :disabled="currentRefuelTabIndex==0"></TabPane>
            </Tabs>
          </template>
          <template v-if="currentTabIndex===4">
            <Tabs v-model="currentTotalTabIndex"
                  class="tabs"
                  key="child6"
                  @on-click="onClickRefuelingSummaryTabs">
              <TabPane v-for="item in totalTabArr"
                       :key="item.name"
                       :label="item.name"></TabPane>
            </Tabs>
          </template>
          <Dropdown class="p-t-10"
                    v-if="checkPermi(['/admin/carFuelReport/export'])">
            <Button class="m-r-10">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="part">
                <ExportTemplate @click.stop="onExportPart"
                                :path="getExportPath"
                                :data="getExportData('part')">
                  <Button>
                    导出部分
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="now">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('now')">
                  <Button>
                    导出本页
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="all">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('all')">
                  <Button>
                    导出全部
                  </Button>
                </ExportTemplate>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="table">
          <Table ref="table"
                 :height="tableHeight"
                 :columns="getColumns"
                 :data="getData"
                 border
                 stripe
                 :loading="tableLoading"
                 :row-class-name="rowClassName"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Echarts from '@/components/common/Echarts';
import { mapActions, mapGetters } from 'vuex';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import { getToday, getYesterday, getNearlyThreeDay, getCurrentMonth, getLastMonth, getYearOrMonthForMonth, getPreMonthDay, getCurrentWeek, formatDate, getNearlySevenDay, getCurrentYearToNow } from '@/utils/dateRangUtil'
import Monthrange from '@/components/common/Monthrange'
import ExportTemplate from '@/components/common/ExportTemplate'
import { checkPermi } from '@/utils/permission'
export default {
  components: { Echarts, MyTreeSelect, Monthrange, ExportTemplate },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          return date && date > new Date()
        }
      },
      dateMonthrange: [],
      echartsData: [],
      fuelConsumptionEcharsData: [],
      oilSpillEcharsData: [],
      refuelEcharsData: [],
      refuelSummaryOptionsData: [],
      refuelSummaryWorkOptionsData: [],       // 加油汇总-作业分布-饼图
      refuelSummaryCartypeOptionsData: [],    // 加油汇总-车型分布-饼图
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        {
          name: '耗油报表'
        },
        {
          name: '百公里耗油查询'
        },
        {
          name: '漏油报表'
        },
        {
          name: '设备加油报表'
        },
        {
          name: '加油汇总'
        },
      ],
      carNumber: '',
      carNumberId: '',
      remoteCar: '',
      remoteCarList: [],
      remoteCarLoading: false,
      selectType: 1,
      selectTypeArr: [
        {
          id: 1,
          name: '按天查询'
        },
        {
          id: 2,
          name: '按月查询'
        },
      ],
      carType: '',
      oilMass: '',
      departmentType: '',
      departmentTypeArr: [
        {
          id: 1,
          name: '全部'
        },
        {
          id: 2,
          name: '项目'
        },
        {
          id: 3,
          name: '标段'
        },
        {
          id: 4,
          name: '部门'
        },
        {
          id: 5,
          name: '班组'
        },
      ],
      departmentName: '',
      departmentId: '',
      date: [],
      dateMonth: '',
      currentOilMassEchartsTabIndex: 0,
      oilMassEchartsTabArr: [
        {
          name: '单车油量变化'
        },
      ],
      currentOilMassTabIndex: 0,
      oilMassTabArr: [
        {
          name: '全部数据'
        },
        {
          name: '加油数据'
        },
        {
          name: '漏油数据'
        },
      ],
      currentOilConsumptionEchartsTabIndex: 0,
      oilConsumptionEchartsTabArr: [
        // {
        //   name: '按车汇总'
        // },
        {
          name: '时间走势'
        },
      ],
      currentOilConsumptionTabIndex: 0,
      oilConsumptionTabArr: [
        {
          name: '油耗统计'
        },
        {
          name: '每日统计'
        },
      ],
      currentOilLeakEchartsTabIndex: 0,
      oilLeakEchartsTabArr: [
        // {
        //   name: '按车汇总'
        // },
        {
          name: '时间走势'
        },
      ],
      currentOilLeakTabIndex: 0,
      oilLeakTabArr: [
        {
          name: '漏油汇总'
        },
        {
          name: '漏油明细'
        },
      ],
      currentRefuelEchartsTabIndex: 0,
      refuelEchartsTabArr: [
        // {
        //   name: '按车汇总'
        // },
        {
          name: '时间走势'
        },
      ],
      currentRefuelTabIndex: 0,
      refuelTabArr: [
        {
          name: '加油汇总'
        },
        {
          name: '加油明细'
        },
        // {
        //   name: '修正记录'
        // },
      ],
      currentContrastEchartsTabIndex: 0,
      contrastEchartsTabArr: [
        {
          name: '车辆加油统计'
        },
      ],
      currentContrastTabIndex: 0,
      contrastTabArr: [
        {
          name: '对比汇总'
        },
        {
          name: '对比明细'
        },
      ],
      refuelAmountArr: [
        {
          title: '加油量',
          value: '0'
        },
        {
          title: '环比增额',
          value: '0'
        },
        {
          title: '环比增长率',
          value: '0%'
        },
      ],
      currentTotalEchartsTabIndex: 0,
      totalEchartsTabArr: [
        {
          name: '加油量分布图'
        },
      ],
      currentTotalTabIndex: 0,
      totalTabArr: [
        {
          name: '各机构加油报表'
        },
        {
          name: '各作业分类加油报表'
        },
        {
          name: '各车型加油报表'
        },
      ],
      totalOptions: {
        dataset: {
          source: []
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          name: '单位：L'
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [{
          type: 'line',
          // type: 'bar',
          // smooth: true,
          barWidth: 30,
        }],
        color: ['#25bb96'],
      },
      // 加油汇总 作业分布饼图
      workPieChartOptions: {
        dataset: {
          source: []
        },
        title: {
          text: '各作业分布',
          left: '20%',
          top: '10%'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a0}<br />{c0}L'
        },
        series: [
          {
            name: '各作业分布',
            type: 'pie',
            radius: ['50%', '70%'],
          }
        ],
        color: ['#7CB9C2', '#5D85E5', '#73C0DE', '#765A9F']
      },
      // 加油汇总 车型分布饼图
      cartypePieChartOptions: {
        dataset: {
          source: []
        },
        title: {
          text: '各车型分布',
          left: '20%',
          top: '10%'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a0}<br />{c0}L'
        },
        series: [
          {
            name: '各车型分布',
            type: 'pie',
            radius: ['50%', '70%'],
          }
        ],
        color: ['#7CB9C2', '#5D85E5', '#73C0DE', '#765A9F']
      },
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      // 耗油 columns
      dataOilConsumption: [],
      columnsOilConsumption: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carnumber',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickOilConsumptionDetail(row)
                }
              }
            }, row.carnumber);
          }
        },
        {
          title: '车辆类型',
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptidforcarName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计耗油量(L)',
          key: 'useOilNum',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.useOilNum.toFixed(2));
          }
        },
        {
          title: '累计漏油量(L)',
          key: 'outOilNum',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.outOilNum.toFixed(2));
          }
        },
        {
          title: '平均油耗(L/天)',
          key: 'useOilEve',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.useOilEve < 0 ? '- -' : params.row.useOilEve.toFixed(2));
          }
        },
        {
          title: '行驶里程(km)',
          key: 'clockMileage',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.clockMileage.toFixed(2));
          }
        },
        {
          title: '百公里油耗(L/100km)',
          key: 'baiKmOil',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.baiKmOil < 0 ? '- -' : params.row.baiKmOil.toFixed(2));
          }
        },
      ],
      dataOilConsumptionEveryday: [],
      columnsOilConsumptionEveryday: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carnumber',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptidforcarName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计耗油量(L)',
          key: 'useOilNum',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.useOilNum.toFixed(2));
          }
        },
        {
          title: '累计漏油量(L)',
          key: 'outOilNum',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.outOilNum.toFixed(2));
          }
        },
        // {
        //   title: '平均油耗(L/天)',
        //   key: 'useOilEve',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '行驶里程(km)',
          key: 'clockMileage',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.clockMileage.toFixed(2));
          }
        },
        {
          title: '百公里油耗(L/100km)',
          key: 'baiKmOil',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.baiKmOil < 0 ? '- -' : params.row.baiKmOil.toFixed(2));
          }
        },
        {
          title: '日期',
          key: 'deviceDate',
          tooltip: true,
          align: 'center'
        },
      ],
      // 百公里耗油 columns
      dataOilConsumptionHundredKm: [],
      columnsOilConsumptionHundredKm: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carnumber',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptidforcarName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计油耗(L)',
          key: 'useOilNum',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.useOilNum.toFixed(2));
          }
        },
        {
          title: '累计里程(km)',
          key: 'clockMileage',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.clockMileage.toFixed(2));
          }
        },
        {
          title: '百公里油耗(L/100km)',
          key: 'baiKmOil',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.baiKmOil < 0 ? '- -' : params.row.baiKmOil.toFixed(2));
          }
        },
      ],
      // 漏油 columns
      dataOilLeakSummary: [],
      columnsOilLeakSummary: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carnumber',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickOilLeakSummaryDetail(row)
                }
              }
            }, row.carnumber);
          }
        },
        {
          title: '车辆类型',
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptidforcarName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计漏油次数',
          key: 'outOilCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计漏油量(L)',
          key: 'outOilNum',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.outOilNum.toFixed(2));
          }
        },
      ],
      dataOilLeakDetail: [],
      columnsOilLeakDetail: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carnumber',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptidforcarName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计漏油次数',
          key: 'outOilCount',
          tooltip: true,
          align: 'center',
        },
        {
          title: '累计漏油量(L)',
          key: 'outOilNum',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.outOilNum.toFixed(2));
          }
        },
        {
          title: '日期',
          key: 'deviceDate',
          tooltip: true,
          align: 'center'
        },
      ],
      // 设备加油 columns
      dataRefuelSummary: [],
      columnsRefuelSummary: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carnumber',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickRefuelSummaryDetail(row)
                }
              }
            }, row.carnumber);
          }
        },
        {
          title: '车辆类型',
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptidforcarName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '加油次数',
          key: 'addOilCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '加油量(L)',
          key: 'addOilNum',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.addOilNum.toFixed(2));
          }
        },
      ],
      dataRefuelDetail: [],
      columnsRefuelDetail: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carnumber',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptidforcarName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '加油次数',
          key: 'addOilCount',
          tooltip: true,
          align: 'center'
        },
        {
          title: '加油量(L)',
          key: 'addOilNum',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.addOilNum.toFixed(2));
          }
        },
        {
          title: '日期',
          key: 'deviceDate',
          tooltip: true,
          align: 'center'
        },
      ],
      // 加油汇总 机构 columns
      dataTotalDepartment: [],
      columnsTotalDepartment: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '机构名称',
          key: 'deptidforcarName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '月份',
          key: 'monTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '行驶总里程(km)',
          key: 'clockMileage',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.clockMileage.toFixed(2));
          }
        },
        {
          title: '环比上月增加额(L)',
          key: 'addOilMonthGrowth',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.addOilMonthGrowth.toFixed(2));
          }
        },
        {
          title: '加油量(L)',
          key: 'addOilNum',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.addOilNum.toFixed(2));
          }
        },
        {
          title: '百公里耗油(L/100km)',
          key: 'baiKmOil',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.baiKmOil.toFixed(2));
          }
        },
      ],
      // 加油汇总 作业分类 columns
      dataTotalWork: [],
      columnsTotalWork: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '作业分类',
          key: 'carcategoryName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '月份',
          key: 'monTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '行驶总里程(km)',
          key: 'clockMileage',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.clockMileage.toFixed(2));
          }
        },
        {
          title: '环比上月增加额(L)',
          key: 'addOilMonthGrowth',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.addOilMonthGrowth.toFixed(2));
          }
        },
        {
          title: '加油量(L)',
          key: 'addOilNum',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.addOilNum.toFixed(2));
          }
        },
        {
          title: '百公里耗油(L/100km)',
          key: 'baiKmOil',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.baiKmOil.toFixed(2));
          }
        },
      ],
      // 加油汇总 车型 columns
      dataTotalCarType: [],
      columnsTotalCarType: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车辆类型',
          key: 'carTypeName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '月份',
          key: 'monTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '行驶总里程(km)',
          key: 'clockMileage',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.clockMileage.toFixed(2));
          }
        },
        {
          title: '环比上月增加额(L)',
          key: 'addOilMonthGrowth',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.addOilMonthGrowth.toFixed(2));
          }
        },
        {
          title: '加油量(L)',
          key: 'addOilNum',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.addOilNum.toFixed(2));
          }
        },
        {
          title: '百公里耗油(L/100km)',
          key: 'baiKmOil',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            return h('span', {}, params.row.baiKmOil.toFixed(2));
          }
        },
      ],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 判断是否点击汇总列表中车牌号跳转的
      isSummaryClick: false,
      tadayArr: null
    };
  },
  watch: {
    // 耗油 watch
    currentOilConsumptionTabIndex (newVal) {
      this.selectArr = []
      if (newVal == 0) {
        // 如果是点击汇总列表中车牌号跳转的 则清除车辆数据并置为false
        if (this.isSummaryClick) {
          this.carNumber = ''
          this.carNumberId = ''
          this.remoteCar = ''
          this.isSummaryClick = false
        }
      }
      this.onClickSearch()
    },
    selectArr (newVal) {
      console.log(newVal);
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            if (this.currentOilConsumptionTabIndex === 0) {
              let data = this.getData.find((items, index) => {
                return item.carId == items.carId
              })
              this.selectIndexArr.push(data.carId)
            }
            if (this.currentOilConsumptionTabIndex === 1) {
              let data = this.getData.find((items, index) => {
                return item.deviceDate == items.deviceDate
              })
              this.selectIndexArr.push(data.deviceDate)
            }

          } if (this.currentTabIndex === 1) {
            {
              let data = this.getData.find((items, index) => {
                return item.carId == items.carId
              })
              this.selectIndexArr.push(data.carId)
            }

          }if (this.currentTabIndex === 2) {
            if (this.currentOilLeakTabIndex === 0) {
              let data = this.getData.find((items, index) => {
                return item.carId == items.carId
              })
              this.selectIndexArr.push(data.carId)
            }
            if (this.currentOilLeakTabIndex === 1) {
              let data = this.getData.find((items, index) => {
                return item.deviceDate == items.deviceDate
              })
              this.selectIndexArr.push(data.deviceDate)
            }

          }
          if (this.currentTabIndex === 3) {
            if (this.currentRefuelTabIndex === 0) {
              let data = this.getData.find((items, index) => {
                return item.carId == items.carId
              })
              this.selectIndexArr.push(data.carId)
            }
            if (this.currentRefuelTabIndex === 1) {
              let data = this.getData.find((items, index) => {
                return item.deviceDate == items.deviceDate
              })
              this.selectIndexArr.push(data.deviceDate)
            }

          } if (this.currentTabIndex === 4) {
            if (this.currentTotalTabIndex === 0) {
              let data = this.getData.find((items, index) => {
                return item.deptidforcarName == items.deptidforcarName
              })
              this.selectIndexArr.push(data.deptidforcarName)
            }
            if (this.currentTotalTabIndex === 1) {
              let data = this.getData.find((items, index) => {
                return item.carcategoryId == items.carcategoryId
              })
              this.selectIndexArr.push(data.carcategoryId)
            }
            if (this.currentTotalTabIndex === 2) {
              let data = this.getData.find((items, index) => {
                return item.carTypeName == items.carTypeName
              })
              this.selectIndexArr.push(data.carTypeName)
            }

          } 
        })
      }
    },
    // 漏油 watch
    currentOilLeakTabIndex (newVal) {
      this.selectArr = []
      if (newVal == 0) {
        if (this.isSummaryClick) {
          this.carNumber = ''
          this.carNumberId = ''
          this.remoteCar = ''
          this.isSummaryClick = false
        }
      }
      this.onClickSearch()
    },
    // 加油 watch
    currentRefuelTabIndex (newVal) {
      this.selectArr = []
      if (newVal == 0) {
        if (this.isSummaryClick) {
          this.carNumber = ''
          this.carNumberId = ''
          this.remoteCar = ''
          this.isSummaryClick = false
        }
      }
      this.onClickSearch()
    },
    selectType (newVal) {
      if (newVal == 1) {
        this.onClickCurrentMonth()
      } else {
        this.onClickCurrentYear()
      }
    }
  },
  computed: {
    ...mapGetters({
      'typeArr': 'getCarTypeAllList',
      'carTreeArr': 'getCarTreeList',
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentOilConsumptionTabIndex) {
            case 0:
              columns = this.columnsOilConsumption
              break;
            case 1:
              columns = this.columnsOilConsumptionEveryday
              break;
            default:
              break;
          }
          break;
        case 1:
          columns = this.columnsOilConsumptionHundredKm
          break;
        case 2:
          switch (this.currentOilLeakTabIndex) {
            case 0:
              columns = this.columnsOilLeakSummary
              break;
            case 1:
              columns = this.columnsOilLeakDetail
              break;
            default:
              break;
          }
          break;
        case 3:
          switch (this.currentRefuelTabIndex) {
            case 0:
              columns = this.columnsRefuelSummary
              break;
            case 1:
              columns = this.columnsRefuelDetail
              break;
            default:
              break;
          }
          break;
        case 4:
          switch (this.currentTotalTabIndex) {
            case 0:
              columns = this.columnsTotalDepartment
              break;
            case 1:
              columns = this.columnsTotalWork
              break;
            case 2:
              columns = this.columnsTotalCarType
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    getData () {
      let data = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentOilConsumptionTabIndex) {
            case 0:
              data = this.dataOilConsumption
              break;
            case 1:
              data = this.dataOilConsumptionEveryday
              break;
            default:
              break;
          }
          break;
        case 1:
          data = this.dataOilConsumptionHundredKm
          break;
        case 2:
          switch (this.currentOilLeakTabIndex) {
            case 0:
              data = this.dataOilLeakSummary
              break;
            case 1:
              data = this.dataOilLeakDetail
              break;
            default:
              break;
          }
          break;
        case 3:
          switch (this.currentRefuelTabIndex) {
            case 0:
              data = this.dataRefuelSummary
              break;
            case 1:
              data = this.dataRefuelDetail
              break;
            default:
              break;
          }
          break;
        case 4:
          switch (this.currentTotalTabIndex) {
            case 0:
              data = this.dataTotalDepartment
              break;
            case 1:
              data = this.dataTotalWork
              break;
            case 2:
              data = this.dataTotalCarType
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return data
    },
    typeArr () {
      let arr = [{
        id: '0',
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getCarTypeAllList)
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
        case 1:
        case 2:
        case 3:
          path = this.$http.exportRefuelSummary
          break;
        case 4:
          path = this.$http.exportRefuelSummaryEx
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateCarTypeAllList',
      'updateCarTreeList',
      'updateDepartmentTreeList'
    ]),
    onExportPart () {
      console.log(111111);
    },
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updateCarTypeAllList()
      this.updateCarTreeList()
      this.updateDepartmentTreeList()
      this.todayArr = getToday().split('-')
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // 获取燃油报表(echarts) 数据
    ggetFuelConsumptionEchartsData () {
      let params = {
        // groupType: this.selectType, // 分组条件 按天还是按月
        carId: this.remoteCar,  // 车牌号
        carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
        depId: this.departmentId
      }
      this.dateJudge(params)
      params.dateType = this.selectType    // 天1 月2
      this.$http.getFuelConsumptionEchars(params).then(res => {
        if (res.code == 200) {
          this.fuelConsumptionEcharsData = res.result
        }
      })
    },
    // 获取耗油列表数据
    getFuelConsumptionList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        isEve: 0,
        carId: this.remoteCar,  // 车牌号
        carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
        depId: this.departmentId
      }
      // oilType 耗油1 漏油2  加油3
      switch (this.currentTabIndex) {
        case 0:
          params.oilType = 1
          break;
        case 2:
          params.oilType = 2
          break;
        case 3:
          params.oilType = 3
          break;
        default:
          break;
      }
      this.dateJudge(params)
      this.tableLoading = true
      this.$http.getFuelConsumptionList(params).then(res => {
        if (res.code == 200) {
          this.tableLoading = false
          this.dataOilConsumption = res.result.rows
          this.dataOilConsumptionHundredKm = res.result.rows
          this.dataOilLeakSummary = res.result.rows
          this.dataRefuelSummary = res.result.rows
          this.totalPage = res.result.total
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 耗油页列表点击车牌号切换
    onClickOilConsumptionDetail (row) {
      this.isSummaryClick = true
      this.remoteCar = row.carId
      this.carNumberId = row.carId
      this.carNumber = row.carnumber
      this.currentOilConsumptionTabIndex = 1
    },
    // 获取耗油每日统计列表数据
    getFuelConsumptionDetailList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        isEve: 1,
        carId: this.remoteCar,  // 车牌号
        carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
        oilType: 1,
        depId: this.departmentId
      }
      this.dateJudge(params)
      this.$http.getFuelConsumptionList(params).then(res => {
        if (res.code == 200) {
          this.dataOilConsumptionEveryday = res.result.rows
          this.totalPage = res.result.total
        }
      })
    },
    // 获取漏油图表 数据
    getOilSpillEchartsData () {
      let params = {
        carId: this.remoteCar,  // 车牌号        
        carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
        depId: this.departmentId
      }
      this.dateJudge(params)
      params.dateType = this.selectType
      this.$http.getOilSpillEchars(params).then(res => {
        if (res.code == 200) {
          this.oilSpillEcharsData = res.result
        }
      })
    },
    // 漏油页列表点击切换
    onClickOilLeakSummaryDetail (row) {
      this.isSummaryClick = true
      this.remoteCar = row.carId
      this.carNumberId = row.carId
      this.carNumber = row.carnumber
      this.currentOilLeakTabIndex = 1
    },
    // 获取漏油报表 明细 数据
    getOilSpillDetailData () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        isEve: 1,
        carId: this.remoteCar,  // 车牌号
        carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
        oilType: 2,
        depId: this.departmentId
      }
      this.dateJudge(params)
      this.$http.getFuelConsumptionList(params).then(res => {
        if (res.code == 200) {
          this.dataOilLeakDetail = res.result.rows
          this.totalPage = res.result.total
        }
      })
    },
    // 获取加油图表 数据
    getRefuelEchartsData () {
      let params = {
        carId: this.remoteCar,  // 车牌号
        carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
        depId: this.departmentId
      }
      this.dateJudge(params)
      params.dateType = this.selectType
      this.$http.getRefuelEchars(params).then(res => {
        if (res.code == 200) {
          this.refuelEcharsData = res.result
        }
      })
    },
    // 加油页面列表点击切换
    onClickRefuelSummaryDetail (row) {
      this.isSummaryClick = true
      this.remoteCar = row.carId
      this.carNumberId = row.carId
      this.carNumber = row.carnumber
      this.currentRefuelTabIndex = 1
    },
    // 获取加油 明细 数据
    getRefuelDetailData () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        isEve: 1,
        carId: this.remoteCar,  // 车牌号
        carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
        oilType: 3,
        depId: this.departmentId
      }
      this.dateJudge(params)
      this.$http.getFuelConsumptionList(params).then(res => {
        if (res.code == 200) {
          this.dataRefuelDetail = res.result.rows
          this.totalPage = res.result.total
        }
      })
    },
    // 切换加油汇总下的tabs
    onClickRefuelingSummaryTabs () {
      this.getRefuelSummaryData()
    },
    // 获取加油汇总数据
    getRefuelSummaryData () {
      let params = {
        deptidforcarId: this.departmentId,   // 机构ID
        carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
        // isEve: 0,
      }

      // 机构类型
      if (this.departmentType != '全部') {
        params.deptidforcarType = this.departmentType
      }
      if (this.dateMonth) {
        let arr = this.dateMonth.split('-')
        let monthArr = getYearOrMonthForMonth(arr[0], arr[1])
        params.startTime = monthArr[0]
        params.endTime = monthArr[1]
      } else {
        if (this.todayArr[2] == '01') {
          let lastMonth
          if (parseInt(this.todayArr[1]) == 1) {
            lastMonth = 12
          } else {
            lastMonth = parseInt(this.todayArr[1]) - 1
            if (lastMonth < 10) {
              lastMonth = '0' + lastMonth
            }
          }
          let monthArr = getYearOrMonthForMonth(this.todayArr[0], lastMonth)
          params.startTime = monthArr[0]
          params.endTime = monthArr[1]
          this.dateMonth = this.todayArr[0] + '-' + lastMonth
        } else {
          let monthArr = getYearOrMonthForMonth(this.todayArr[0], this.todayArr[1])
          params.startTime = monthArr[0]
          params.endTime = monthArr[1]
          this.dateMonth = this.todayArr[0] + '-' + this.todayArr[1]
        }
      }
      switch (this.currentTotalTabIndex) {
        case 0:
          this.getRefuelSummaryDept(params)
          break;
        case 1:
          this.getJobClassificationRefuel(params)
          break;
        case 2:
          this.getModelsRefuel(params)
          break;
        default:
          break;
      }
      this.getRefuelSummaryEchars(params)
    },
    // 获取加油汇总 echars 数据
    getRefuelSummaryEchars (params) {
      params.dateType = 1
      delete params.page
      delete params.size
      delete params.dateType
      // this.$http.getRefuelEchars(params).then(res => {
      //   if (res.code == 200) {
      //     this.refuelSummaryOptionsData = res.result
      //   }
      // })
      this.$http.getRefuelSummaryPieEchars(params).then(res => {
        if (res.code == 200) {
          let data = res.result
          this.refuelSummaryWorkOptionsData = data.jobDistributionEChars
          this.refuelSummaryCartypeOptionsData = data.vehicleTypeDistributionEChars
          this.refuelAmountArr[0].value = data.addOilNum + ' L'
          this.refuelAmountArr[1].value = data.addOilMonthGrowthNum + ' L'
          this.refuelAmountArr[2].value = data.addOilMonthGrowthPercentage + ' %'
        }
      })
    },
    // 获取加油汇总各机构加油列表
    getRefuelSummaryDept (params) {
      params.page = this.pageNum
      params.size = this.pageSize
      this.$http.getRefuelSummaryDept(params).then(res => {
        if (res.code == 200) {
          this.dataTotalDepartment = res.result.rows
          this.totalPage = res.result.total
        }
      })
    },
    // 获取各作业分类加油报表
    getJobClassificationRefuel (params) {
      params.page = this.pageNum
      params.size = this.pageSize
      this.$http.getJobClassificationRefuel(params).then(res => {
        if (res.code == 200) {
          this.dataTotalWork = res.result.rows
          this.totalPage = res.result.total
        }
      })
    },
    // 获取各车型加油报表
    getModelsRefuel (params) {
      params.page = this.pageNum
      params.size = this.pageSize
      this.$http.getModelsRefuel(params).then(res => {
        if (res.code == 200) {
          this.dataTotalCarType = res.result.rows
          this.totalPage = res.result.total
        }
      })
    },
    // 按天or按月 
    dateJudge (params) {
      // 天
      if (this.date[0]) {
        params.startTime = this.date[0]
        params.endTime = this.date[1]
      }
      return params
    },
    // 修改车牌号 
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.carNumberId = idArr.join()
      this.carNumber = valueArr.join()
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    // 日期回调
    onChangeDate (date) {
      if (this.currentTabIndex == 4) {
        this.dateMonth = date
      } else {
        let millisecond = 1000 * 60 * 60 * 24
        let startDate = new Date(date[0]).getTime()
        let endDate = new Date(date[1]).getTime()
        if (endDate - startDate < millisecond * 2) {
          this.date = getCurrentMonth(false)
          this.$Message.info('最少选择三天')
        } else {
          this.date = date
        }
      }
      this.buttonIndex = null
    },
    // 月份回调
    onChangeMonthrange (arr) {
      let startMonthArr = arr.month[0].split('-')
      let endMonthArr = arr.month[1].split('-')
      if (parseInt(endMonthArr[1]) - parseInt(startMonthArr[1]) >= 2) {
        this.date = arr.day
      } else {
        this.date = []
        this.dateMonthrange = []
        this.$Message.info('最少选择三个月')
      }
      this.buttonIndex = null
    },
    // 批量
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.carId)) {
        return 'table-visited-bgcolor'
      }
      if (this.currentTabIndex === 0) {
        if (this.currentOilConsumptionTabIndex === 0) {
          if (this.selectIndexArr.includes(row.carId)) {
            return 'table-bgcolor'
          }
        }
        if (this.currentOilConsumptionTabIndex === 1) {
          if (this.selectIndexArr.includes(row.deviceDate)) {
            return 'table-bgcolor'
          }
        }

      } if (this.currentTabIndex === 1) {
        {
          if (this.selectIndexArr.includes(row.carId)) {
            return 'table-bgcolor'
          }
        }
        

      } if (this.currentTabIndex === 2) {
        if (this.currentOilLeakTabIndex === 0) {
          if (this.selectIndexArr.includes(row.carId)) {
            return 'table-bgcolor'
          }
        }
        if (this.currentOilLeakTabIndex === 1) {
          if (this.selectIndexArr.includes(row.deviceDate)) {
            return 'table-bgcolor'
          }
        }

      } if (this.currentTabIndex === 3) {
        if (this.currentRefuelTabIndex === 0) {
          if (this.selectIndexArr.includes(row.carId)) {
            return 'table-bgcolor'
          }
        }
        if (this.currentRefuelTabIndex === 1) {
          if (this.selectIndexArr.includes(row.deviceDate)) {
            return 'table-bgcolor'
          }
        }

      } if (this.currentTabIndex === 4) {
        if (this.currentTotalTabIndex === 0) {
          if (this.selectIndexArr.includes(row.deptidforcarName)) {
            return 'table-bgcolor'
          }
        }
        if (this.currentTotalTabIndex === 1) {
          if (this.selectIndexArr.includes(row.carcategoryId)) {
            return 'table-bgcolor'
          }
        }
        if (this.currentTotalTabIndex === 2) {
          if (this.selectIndexArr.includes(row.carTypeName)) {
            return 'table-bgcolor'
          }
        }
      } 
    },
    // 近三天
    onClickNearlyThreeDay () {
      this.date = getNearlyThreeDay(false)
      this.getList()
      this.buttonIndex = 2
    },
    // 近七天
    onClickNearlySevenDay () {
      this.date = getNearlySevenDay(false)
      this.getList()
      this.buttonIndex = 0
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth(false)
      let time = getCurrentMonth()[0].split('-')
      this.dateMonth = time[0] + '-' + time[1]
      this.buttonIndex = 1
      let millisecond = 1000 * 60 * 60 * 24
      if (new Date(this.date[1]).getTime() - new Date(this.date[0]).getTime() < millisecond * 2) {
        this.$Message.info('最少选择三天')
        this.fuelConsumptionEcharsData = []
        this.dataOilConsumptionEveryday = []
        this.dataOilConsumption = []
        this.dataOilConsumptionHundredKm = []
        this.dataOilLeakSummary = []
        this.dataRefuelSummary = []
        this.oilSpillEcharsData = []
        this.dataOilLeakDetail = []
        this.refuelEcharsData = []
        this.dataRefuelDetail = []
        this.totalPage = 0
        return
      }
      this.getList()
    },
    // 近几个月
    onClickPreMonthDay (num) {
      this.date = getPreMonthDay(num)
      let startMonth = this.date[0].split('-')
      let endMonth = this.date[1].split('-')
      this.dateMonthrange = [startMonth[0] + '-' + startMonth[1], endMonth[0] + '-' + endMonth[1]]
      this.getList()
      switch (num) {
        case 3:
          this.buttonIndex = 3
          break;
        case 6:
          this.buttonIndex = 4
          break;
        default:
          break;
      }
    },
    // 本年
    onClickCurrentYear () {
      this.date = getCurrentYearToNow()
      let startMonth = this.date[0].split('-')
      let endMonth = this.date[1].split('-')
      this.dateMonthrange = [startMonth[0] + '-' + startMonth[1], endMonth[0] + '-' + endMonth[1]]
      this.getList()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 重置按钮
    onResetSearchData () {
      this.resetSearchData()
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // tabs 标签页切换
    onClickTabs () {
      this.selectArr = []
      this.resetSearchData()
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // 车牌号远程搜索
    remoteMethod (query) {
      if (query !== '') {
        this.remoteCarLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchCarList(params).then((res) => {
          if (res.code === 200) {
            this.remoteCarLoading = false
            let data = res.result
            this.remoteCarList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteCarLoading = false
            this.remoteCarList = []
          }
        })
      } else {
        this.remoteCarList = []
      }
    },
    // 车牌号远程搜索框内容改变时
    onQueryChangeRemoteCar (value) {
      if (!value) {
        this.remoteCarList = []
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.pageSize = 20
      this.getList()
    },
    // 判断在哪个tabs 栏
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentOilConsumptionTabIndex) {
            case 0:
              // 燃油图表
              this.ggetFuelConsumptionEchartsData()
              // 燃油table
              this.getFuelConsumptionList()
              break;
            case 1:
              this.ggetFuelConsumptionEchartsData()
              this.getFuelConsumptionDetailList()
              break;
            default:
              break;
          }
          break;
        case 1:
          // 燃油table
          this.getFuelConsumptionList()
          break;
        case 2:
          switch (this.currentOilLeakTabIndex) {
            case 0:
              this.getOilSpillEchartsData()
              this.getFuelConsumptionList()
              break;
            case 1:
              this.getOilSpillEchartsData()
              this.getOilSpillDetailData()
              break;
            default:
              break;
          }
          break;
        case 3:
          switch (this.currentRefuelTabIndex) {
            case 0:
              this.getRefuelEchartsData()
              this.getFuelConsumptionList()
              break;
            case 1:
              this.getRefuelEchartsData()
              this.getRefuelDetailData()
              break;
            default:
              break;
          }
          break;
        case 4:
          this.getRefuelSummaryData()
          break;
        default:
          break;
      }
    },
    // 重置搜索条件
    resetSearchData () {
      this.pageNum = 1
      this.pageSize = 20
      this.departmentId = ''
      this.departmentName = ''
      this.carType = ''
      this.selectType = 1
      this.carNumber = ''
      this.carNumberId = ''
      this.remoteCar = ''
      this.departmentType = ''
      this.date = []
      this.dateMonth = ''
      if (this.$refs.formDate) {
        this.$refs.formDate.handleClear()
      }
      this.buttonIndex = null
      switch (this.currentTabIndex) {
        case 0:
          this.currentOilConsumptionTabIndex = 0
          break;
        case 2:
          this.currentOilLeakTabIndex = 0
          break;
        case 3:
          this.currentRefuelTabIndex = 0
          break;
        default:
          break;
      }
    },
    onChangeDropdown (a) {
      console.log(a);
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            isEve: this.currentOilConsumptionTabIndex,
            carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
          }
          data.oilType = 1
          this.dateJudge(data)
          switch (value) {
            case "now":
              if (this.currentOilConsumptionTabIndex == 0) {
                data.carIds = this.getData.map(item => {
                  return item.carId
                })
              } else {
                data.carIds = [this.remoteCar]
                data.times = this.getData.map(item => {
                  return item.deviceDate
                })
              }
              break;
            case "part":
              if (this.currentOilConsumptionTabIndex == 0) {
                data.carIds = this.selectArr.map(item => {
                  return item.carId
                })
              } else {
                data.carIds = [this.remoteCar]
                data.times = this.selectArr.map(item => {
                  return item.deviceDate
                })
              }
              break;
            case "all":
              if (this.currentOilConsumptionTabIndex == 1) {
                data.carIds = [this.remoteCar]
              }
              break;
            default:
              break;
          }
          break;
        case 1:
          data = {
            carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
          }
          data.oilType = 4
          this.dateJudge(data)
          switch (value) {
            case "now":
              data.carIds = this.getData.map(item => {
                return item.carId
              })
              break;
            case "part":
              data.carIds = this.selectArr.map(item => {
                return item.carId
              })
              break;
            case "all":
              if (this.currentOilConsumptionTabIndex == 1) {
                data.carIds = [this.remoteCar]
              }
              break;
            default:
              break;
          }
          break;
        case 2:
          data = {
            isEve: this.currentOilLeakTabIndex,
            carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
          }
          data.oilType = 2
          this.dateJudge(data)
          switch (value) {
            case "now":
              if (this.currentOilLeakTabIndex == 0) {
                data.carIds = this.getData.map(item => {
                  return item.carId
                })
              } else {
                data.carIds = [this.remoteCar]
                data.times = this.getData.map(item => {
                  return item.deviceDate
                })
              }
              break;
            case "part":
              if (this.currentOilLeakTabIndex == 0) {
                data.carIds = this.selectArr.map(item => {
                  return item.carId
                })
              } else {
                data.carIds = [this.remoteCar]
                data.times = this.selectArr.map(item => {
                  return item.deviceDate
                })
              }
              break;
            case "all":
              if (this.currentOilLeakTabIndex == 1) {
                data.carIds = [this.remoteCar]
              }
              break;
            default:
              break;
          }
          break;
        case 3:
          data = {
            isEve: this.currentRefuelTabIndex,
            carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
          }
          data.oilType = 3
          this.dateJudge(data)
          switch (value) {
            case "now":
              if (this.currentRefuelTabIndex == 0) {
                data.carIds = this.getData.map(item => {
                  return item.carId
                })
              } else {
                data.carIds = [this.remoteCar]
                data.times = this.getData.map(item => {
                  return item.deviceDate
                })
              }
              break;
            case "part":
              if (this.currentRefuelTabIndex == 0) {
                data.carIds = this.selectArr.map(item => {
                  return item.carId
                })
              } else {
                data.carIds = [this.remoteCar]
                data.times = this.selectArr.map(item => {
                  return item.deviceDate
                })
              }
              break;
            case "all":
              if (this.currentRefuelTabIndex == 1) {
                data.carIds = [this.remoteCar]
              }
              break;
            default:
              break;
          }
          break;
        case 4:
          data = {
            deptidforcarId: this.departmentId,   // 机构ID
            carTypeId: this.carType == 0 ? '' : this.carType,        // 车辆类型id
          }
          this.dateJudge(data)
          switch (value) {
            case "now":
              switch (this.currentTotalTabIndex) {
                case 0:
                  data.oilType = 1
                  data.deptidforcarIds = this.getData.map(item => {
                    return item.deptidforcarName
                  })
                  break;
                case 1:
                  data.oilType = 2
                  data.carcategoryNames = this.getData.map(item => {
                    return item.carcategoryId
                  })
                  break;
                case 2:
                  data.oilType = 3
                  data.carTypeIds = this.getData.map(item => {
                    return item.carTypeName
                  })
                  break;
                default:
                  break;
              }
              break;
            case "part":
              switch (this.currentTotalTabIndex) {
                case 0:
                  data.oilType = 1
                  data.deptidforcarIds = this.selectArr.map(item => {
                    return item.deptidforcarName
                  })
                  break;
                case 1:
                  data.oilType = 2
                  data.carcategoryNames = this.selectArr.map(item => {
                    return item.carcategoryId
                  })
                  break;
                case 2:
                  data.oilType = 3
                  data.carTypeIds = this.selectArr.map(item => {
                    return item.carTypeName
                  })
                  break;
                default:
                  break;
              }
              break;
            case "all":
              switch (this.currentTotalTabIndex) {
                case 0:
                  data.oilType = 1
                  break;
                case 1:
                  data.oilType = 2
                  break;
                case 2:
                  data.oilType = 3
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }

          break;
        default:
          break;
      }
      return data
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.noData {
  height: 400px;
  font-size: 16px;
  img {
    width: 150px;
    height: 100px;
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  overflow: auto;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
      .topbar-search-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .topbar-search-item-key {
          width: 70px;
          text-align: right;
          color: #a4a4a4;
        }
      }
    }
  }
  .content {
    .section {
      padding: 0px 15px 15px 15px;
      background-color: #fff;
      margin-top: 16px;
      .section-title {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #ddd;
        span {
          font-weight: 700;
          font-size: 16px;
          padding-left: 10px;
          border-left: 2px solid #25bb96;
        }
      }
      .section-list {
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .section-item {
          span {
            font-weight: 700;
          }
        }
      }
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>